import React from "react";
import { ContactForm } from "@/components/contact-form";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import { Layout } from "@/components/layout";
import { ApplicationForm } from "@/components/application-form";

const PHPDeveloperPage = () => {
  return (
    <>
      <GatsbySeo
        title="PHP Developer - Careers | Tectra Technologies"
        canonical="https://www.tectratechnologies.com/careers/php-developer/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/careers/php-developer/",
          title: "PHP Developer - Careers | Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white mt-20 lg:mt-24">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-3xl font-poppins font-normal captalize tracking-tight">
              PHP Developer
            </h2>

            <p className="text-lg font-poppins font-thin text-black">
              Chennai, IN - Full Time
            </p>

            <div className="mt-6">
              <ApplicationForm job_type="PHP Developer" />
            </div>
          </div>
        </div>

        <div className="bg-white mt-4">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <div className="grid lg:grid-cols-12">
              <div className="lg:col-span-12">
                <h5 className="mb-4 mt-6 font-medium underline text-lg">
                  Requirements:-
                </h5>

                <ul className="list-disc text-black ml-8">
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Bachelor’s degree in computer science or a similar field.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Knowledge of PHP web frameworks including Yii, Laravel, and
                    CodeIgniter.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Knowledge of front-end technologies including CSS3,
                    JavaScript, and HTML5.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Understanding of object-oriented PHP programming.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Previous experience creating scalable applications.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Proficient with code versioning tools including Git,
                    Mercurial, CVS, and SVN.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Familiarity with SQL/NoSQL databases.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Ability to project manage.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Good problem-solving skills.
                  </li>
                </ul>

                <h5 className="mb-4 mt-6 font-medium underline text-lg">
                  Job Responsibilities:
                </h5>

                <ul className="list-disc mb-0 ml-8">
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Conducting analysis of website and application requirements.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Writing back-end code and building efficient PHP modules.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Developing back-end portals with an optimized database.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Troubleshooting application and code issues.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Integrating data storage solutions.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Responding to integration requests from front-end
                    developers.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Finalizing back-end features and testing web applications.
                  </li>
                  <li className="text-sm font-poppins font-normal text-black mt-2">
                    Updating and altering application features to enhance
                    performance.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PHPDeveloperPage;
